import React, {Component} from "react";
import '../App.css'
import Zoodles1 from './pdf/veggies/SeasonedZoodles.pdf';

class SideDishes extends Component {
    render() {
        return (
            <p>
                <p className="Catagories">Side Dishes</p>
                <ul>
                    <li><a href={Zoodles1} target="_blank" rel="noreferrer">Zoodles</a></li>
                </ul>
            </p>
        )
    }
}
export default SideDishes;
